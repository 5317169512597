import styled from "styled-components";



export const Item = styled.div` 

  @media all and (min-width: 320px) and (max-width: 720px)  {
   width: 100%;
   height: auto;
   margin: 10px;
   padding: 10px;
  } 

 `;

export const SliderContainer = styled.div` 
@media all and (max-width: 1200px){
   display: none;
  } 


& .cuwwaO button {
 color: #76f09b;
}
& .diGzuw button {
 color: #073cb4;
}
@media all and (max-width: 1200px) {
    width: 100%;
  } 

 `;




